import React from 'react'
import { Route, Routes, Navigate } from "react-router-dom";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import HomePage from "./pages/HomePage";
import PhosphorIconInit from "./helper/PhosphorIconInit";
import ShopPage from "./pages/ShopPage";  
import ProductDetailsPage from "./pages/ProductDetailsPage";
import CartPage from "./pages/CartPage";
import InvoicePage from "./pages/InvoicePage";
import LoginPage from "./pages/LoginPage";
import AccountPage from "./pages/AccountPage";
import CategoryPage from "./pages/CategoryPage";

function App() {
  return (
    <>
      <RouteScrollToTop />
      <PhosphorIconInit />

      <Routes>
        <Route path='/' element={localStorage.userName ? <HomePage /> : <LoginPage />} />
        <Route path="/login" element={<ProtectedRoute component={LoginPage} />} />
        <Route path="/shop/:categoryId" element={<ProtectedRoute component={ShopPage} />} />
        <Route path="/shop" element={<ProtectedRoute component={ShopPage} />} />
        <Route path="/product/:productCode" element={<ProtectedRoute component={ProductDetailsPage} />} />
        <Route path="/cart" element={<ProtectedRoute component={CartPage} />} />
        <Route path="/invoice" element={<ProtectedRoute component={InvoicePage} />} />
        <Route path="/account" element={<ProtectedRoute component={AccountPage} />} />
        <Route path="/category" element={<ProtectedRoute component={CategoryPage} />} />
      </Routes>
    </>
  );
}

const ProtectedRoute = ({ component: Component }) => {
  return localStorage.userName ? <Component /> : <Navigate to="/" />;
};

export default App;