import React, { useState } from "react";
import Preloader from "../helper/Preloader";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BottomFooter from "../components/BottomFooter";
import BottomNav from '../components/BottomNav';
import CategorySection from '../components/CategorySection';
import ScrollToTop from "react-scroll-to-top";

const CategoryPage = () => {
  return (
    <>
      {/* Preloader */}
      <Preloader />

      {/* ScrollToTop */}
      <ScrollToTop smooth color="#0771a6" />

      {/* Header */}
      <Header />

      {/* CategorySection */}
      <CategorySection />

      {/* BottomNav */}
      <BottomNav />

      {/* Footer */}
      <Footer />

      {/* BottomFooter */}
      <BottomFooter />
    </>
  );
};

export default CategoryPage;
