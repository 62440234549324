import React, { useEffect, useState } from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './category-list.css';
import { Link } from 'react-router-dom';

const CategorySection = () => {
    const [parentCategories, setParentCategories] = useState([]);
    const [childCategories, setChildCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);

    useEffect(() => {
        let isMountedCategory = true;

        const fetchCategories = async () => {
            try {
                const response = await axios({
                    url: process.env.REACT_APP_API_URL + "/api/category/all",
                    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
                    method: "GET"
                });
                if (isMountedCategory) {
                    const parentCategories = response.data.category_info.filter(cat => cat.parent_id == 0);
                    setParentCategories(parentCategories);

                    const childCategories = response.data.category_info.filter(cat => cat.parent_id != 0);
                    setChildCategories(childCategories);
                }
            } catch (error) {
                if (isMountedCategory)
                    toast.error('Сүлжээний алдаа гарлаа!' + error, {autoClose: 3500});
            }
        };

        fetchCategories();
        
        return () => {
            isMountedCategory = false;
        };
    }, [selectedCategory]);


    return (
        <section className="shipping mt-20">
            <ToastContainer />
            <div className="list-container container-lg">
                <aside className="sidebar">
                    <div className="shop-sidebar__box border border-gray-100 rounded-8 p-32">
                        <h6 className="text-lg border-bottom border-gray-100 pb-24">
                            Үндсэн ангилал
                        </h6>
                        <ul className="max-h-540 overflow-y-auto scroll-sm">
                            {
                                (parentCategories || []).map((cat) => (
                                    <li className="mb-24 text-sm">
                                        <Link to='#' onClick={() => setSelectedCategory(cat.id)}>
                                            <span className="text-main-600 text-xl">
                                                <i className="pi pi-th-large" />
                                            </span>
                                            <span className={`text-sm text-gray-900 ${selectedCategory == cat.id && 'fw-bold'}`}>
                                                &nbsp;{cat.cat_name}
                                            </span>
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </aside>

                {/* Main Content */}
                <main className="main-content">
                    <h6 className="text-lg border-bottom border-gray-100 pb-24">
                        Дэд ангилал
                    </h6>
                    <div className="grid">
                    {
                        (childCategories.filter((i) => i.parent_id == selectedCategory)).length > 0 ?
                        (childCategories.filter((i) => i.parent_id == selectedCategory).map((cat) => (
                            <div className="feature-item text-center">
                                <div className="feature-item__thumb">
                                    <Link to={"/shop/" + cat.id} className="w-100 h-100 flex-center">
                                        <img src={'https://ingumel.mn/backend/' +  cat.cat_icon } />
                                    </Link>
                                </div>
                                <div className="feature-item__content mt-16">
                                    <h6 className="text-sm mb-5">
                                        <Link to={"/shop/" + cat.id} className="text-inherit">
                                            {cat.cat_name}
                                        </Link>
                                    </h6>
                                    <span className="text-sm text-gray-400">{cat.products} бараа</span>
                                </div>
                            </div>
                        ))) : (<span className='text-gray-900 w-100 text-center'>Өгөгдөл хоосон байна</span>)
                    }
                    </div>
                </main>
            </div>
        </section>

    )
}

export default CategorySection