import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const Recommended = () => {
    const [productsAll, setProductsAll] = useState();
    // const [productsNew, setProductsNew] = useState();
    const [exchRate, setExchRate] = useState(0);
    // const [productsPopular, setProductsPopular] = useState();

    useEffect(() => {
        let isMountedAll = true;
        // let isMountedNew = true;
        // let isMountedPopular = true;

        const fetchProductsAll = async () => {
            try {
                const response = await axios({
                    url: process.env.REACT_APP_API_URL + "/api/product/col",
                    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
                    data: JSON.stringify({ col : "" }),
                    method: "POST"
                });
                if (isMountedAll) {
                    setProductsAll(response.data.product_info);
                    setExchRate(response.data.exchangeRate);
                }
            } catch (error) {
                if (isMountedAll)
                    toast.error('Сүлжээний алдаа гарлаа!' + error, {autoClose: 3500});
            }
        };

        // const fetchProductsNew = async () => {
        //     try {
        //         const response = await axios({
        //             url: process.env.REACT_APP_API_URL + "/api/product/col",
        //             headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
        //             data: JSON.stringify({ col : 1 }),
        //             method: "POST"
        //         });
        //         if (isMountedNew) {
        //             setProductsNew(response.data.product_info);
        //             setExchRate(response.data.exchangeRate);
        //         }
        //     } catch (error) {
        //         if (isMountedNew)
        //             toast.error('Сүлжээний алдаа гарлаа!' + error, {autoClose: 3500});
        //     }
        // };

        // const fetchProductsPopular = async () => {
        //     try {
        //         const response = await axios({
        //             url: process.env.REACT_APP_API_URL + "/api/product/col",
        //             headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
        //             data: JSON.stringify({ col : "order_cnt" }),
        //             method: "POST"
        //         });
        //         if (isMountedPopular) {
        //             setProductsPopular(response.data.product_info);
        //             setExchRate(response.data.exchangeRate);
        //         }
        //     } catch (error) {
        //         if (isMountedPopular)
        //             toast.error('Сүлжээний алдаа гарлаа!' + error, {autoClose: 3500});
        //     }
        // };

        fetchProductsAll();
        // fetchProductsNew();
        // fetchProductsPopular();
        
        return () => {
            isMountedAll = false;
            // isMountedNew = false;
            // isMountedPopular = false;
        };
    }, []);

    return (
        <section className="recommended mt-60 mb-10">
            <ToastContainer />
            <div className="container container-lg">
                <div className="section-heading flex-between flex-wrap gap-16">
                    <h5 className="mb-0 text-xl">Барааны жагсаалт</h5>
                    <ul className="nav common-tab nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active text-sm"
                                id="pills-all-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-all"
                                type="button"
                                role="tab"
                                aria-controls="pills-all"
                                aria-selected="true"
                            >
                                Шинэ бараанууд
                            </button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <button
                                className="nav-link text-sm"
                                id="pills-new-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-new"
                                type="button"
                                role="tab"
                                aria-controls="pills-new"
                                aria-selected="false"
                            >
                                Шинэ бараанууд
                            </button>
                        </li> */}
                        {/* <li className="nav-item" role="presentation">
                            <button
                                className="nav-link text-sm"
                                id="pills-popular-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-popular"
                                type="button"
                                role="tab"
                                aria-controls="pills-popular"
                                aria-selected="false"
                            >
                                Хамгийн эрэлттэй бараанууд
                            </button>
                        </li> */}
                    </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="pills-all"
                        role="tabpanel"
                        aria-labelledby="pills-all-tab"
                        tabIndex={0}
                    >
                        <div className="row g-12">
                        {
                            (productsAll || []).map((pro) => (
                                <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div className="vendors-two-item rounded-12 overflow-hidden bg-color-three border border-neutral-50 hover-border-main-600 transition-2">
                                        <div className='vendors-two-item__top bg-overlay style-two position-relative'>
                                            <div className='vendors-two-item__thumbs' style={{height: '13rem'}}>
                                                {/* <img src='https://react.marketpro.wowtheme7.com/assets/images/thumbs/vendors-two-img1.png' className="cover-img"/> */}
                                                <img src={'https://ingumel.mn/backend/' + pro.pro_image} className="cover-img"/>
                                            </div>
                                            <div className='position-absolute top-0 inset-inline-start-0 w-100 h-100 p-24 z-1 d-flex flex-column justify-content-between'>
                                                <div className='d-flex align-items-center justify-content-between'></div>
                                                <div className='mt-16'>
                                                    <h6 className='text-white fw-semibold mb-12 text-center'>
                                                        <Link to={"/product/" + pro.pro_code} className="text-line-2 text-md hover-text-white">
                                                            {pro.pro_name}
                                                        </Link>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="vendors-two-item__content p-24 flex-grow-1">
                                            <div className="d-flex flex-column gap-14">
                                                <div className="flex-align gap-8">
                                                    <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                        <i className="pi pi-th-large" />
                                                    </span>
                                                    <span className="text-sm text-gray-900">
                                                        {pro.cat_name}
                                                    </span>
                                                </div>
                                                <div className="flex-align gap-8">
                                                    <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                        <i className="pi pi-hashtag" />
                                                    </span>
                                                    <span className="text-sm text-gray-900">
                                                        Код: {pro.pro_code}
                                                    </span>
                                                </div>
                                                <div className="flex-align gap-8">
                                                    <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                        <i className="pi pi-money-bill" />
                                                    </span>
                                                    <span className="text-sm text-gray-900">
                                                        Үнэ: ¥{pro.pro_price}
                                                        <span>&nbsp;/&nbsp;{pro.pro_price * parseInt(exchRate)}₮</span>{" "}
                                                    </span>
                                                </div>
                                            </div>
                                            {/* <Link to={"/product/" + pro.pro_code}
                                                className="btn bg-main-600 hover-bg-main-700 text-white py-12 px-24 rounded-8 flex-center gap-8 fw-medium mt-24">
                                                <span className="text-xl d-flex text-white">
                                                    <i className="pi pi-list" />
                                                </span>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                            <div class="section-heading">
                                <div class="flex-between flex-wrap gap-8">
                                    <h5 class="mb-0"></h5>
                                    <Link to="/shop"
                                        className="mt-16 btn btn-main text-sm fw-medium d-inline-flex align-items-center rounded-pill gap-8"
                                        tabIndex={0}
                                    >
                                        Бүх барааг үзэх
                                        <span className="icon text-md d-flex">
                                            <i className="ph ph-arrow-right" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div
                        className="tab-pane fade"
                        id="pills-new"
                        role="tabpanel"
                        aria-labelledby="pills-new-tab"
                        tabIndex={1}
                    >
                        <div className="row g-12">
                        {
                            (productsNew || []).map((pro) => (
                                <div className="col-xxl-2 col-lg-3 col-sm-4 col-6">
                                    <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                                        <span className="product-card__badge bg-warning-600 px-8 py-4 text-xs text-white">
                                            Шинэ
                                        </span>
                                        <Link
                                            to={"/product/" + pro.pro_code}
                                            className="product-card__thumb flex-center">
                                            <img src={'https://ingumel.mn/backend/' + pro.pro_image} className="rounded-8" />
                                        </Link>
                                        <div className="product-card__content p-sm-2">
                                            <h6 className="title fw-bold mt-5 mb-8 text-center">
                                                <Link to={"/product/" + pro.pro_code} className="link text-line-2 text-md" style={{wordWrap: 'break-all'}}>
                                                    {pro.pro_name.length > 15 ? pro.pro_name.substr(0, 15) + '...' : pro.pro_name}
                                                </Link>
                                            </h6>
                                            <div className="flex-align gap-4 mt-12 mb-8">
                                                <span className="text-main-600 text-xs d-flex">
                                                    <i className='pi pi-th-large' />
                                                </span>
                                                <span className="text-gray-500 text-sm">
                                                    &nbsp;{pro.cat_name.length > 22 ? pro.cat_name.substr(0, 22) + '...' : pro.cat_name}
                                                </span>
                                            </div>
                                            <div className="product-card__content mt-12 mb-8">
                                                <div className="flex-align gap-6 mb-8">
                                                    <span className="text-gray-500 text-sm">Барааны код: {pro.pro_code}</span>
                                                </div>
                                                <div className="flex-align gap-6 mb-8">
                                                    <span className="text-gray-500 text-sm">Захиалах доод хэмжээ: {pro.order_cnt}</span>
                                                </div>
                                                <div className="product-card__price gap-6  mb-8">
                                                    <span className="text-heading text-sm">
                                                        Нэгж үнэ: ¥{pro.pro_price}
                                                        <span>&nbsp;/&nbsp;{pro.pro_price * parseInt(exchRate)}₮</span>{" "}
                                                    </span>
                                                </div> */}
                                                {/* <div className="product-card__price mt-12 mb-8">
                                                    <QuantityControl 
                                                        initialQuantity={item.quantity}
                                                        updateQty={(newQuantity) => updateQty(item.id, newQuantity)}
                                                    />
                                                </div> */}
                                                {/* <Link
                                                    to={"/product" + pro.pro_code}
                                                    className="product-card__cart text-sm btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 w-100 justify-content-center mt-12"
                                                >
                                                     <i className="ph ph-eye" /> Дэлгэрэнгүй
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                            <div class="section-heading">
                                <div class="flex-between flex-wrap gap-8">
                                    <h5 class="mb-0"></h5>
                                    <Link to="/shop/1"
                                        className="mt-16 btn btn-main fw-medium d-inline-flex align-items-center rounded-pill gap-8"
                                        tabIndex={0}
                                    >
                                        Бүх шинэ барааг үзэх
                                        <span className="icon text-xl d-flex">
                                            <i className="ph ph-arrow-right" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div
                        className="tab-pane fade"
                        id="pills-popular"
                        role="tabpanel"
                        aria-labelledby="pills-popular-tab"
                        tabIndex={2}
                    >
                        <div className="row g-12">
                        {
                            (productsPopular || []).map((pro) => (
                                <div className="col-xxl-2 col-lg-3 col-sm-4 col-6">
                                    <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                                        <span className="product-card__badge bg-info-600 px-8 py-4 text-xs text-white">
                                            Хамгийн эрэлттэй
                                        </span>
                                        <Link
                                            to={"/product/" + pro.pro_code}
                                            className="product-card__thumb flex-center">
                                            <img src={'https://ingumel.mn/backend/' + pro.pro_image} />
                                        </Link>
                                        <div className="product-card__content p-sm-2">
                                            <h6 className="title fw-bold mt-12 mb-8 text-center word-break">
                                                <Link to={"/product/" + pro.pro_code} className="link text-line-2 text-md word-break">
                                                    {pro.pro_name}
                                                </Link>
                                            </h6>
                                            <div className="flex-align gap-4 mt-12 mb-8">
                                                <span className="text-main-600 text-xs d-flex">
                                                    <i className='pi pi-th-large' />
                                                </span>
                                                <span className="text-gray-500 text-sm">
                                                    &nbsp;{pro.cat_name}
                                                </span>
                                            </div>
                                            <div className="product-card__content mt-12 mb-8">
                                                <div className="flex-align gap-6 mb-8">
                                                    <span className="text-gray-500 text-sm">Барааны код: {pro.pro_code}</span>
                                                </div>
                                                <div className="product-card__price mt-12 mb-8">
                                                    <span className="text-heading text-sm">
                                                        Нэгж үнэ: ¥{pro.pro_price}
                                                        <span>&nbsp;/&nbsp;{pro.pro_price * parseInt(exchRate)}₮</span>{" "}
                                                    </span>
                                                </div>
                                                <Link
                                                    to="/cart"
                                                    className="product-card__cart text-sm btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 w-100 justify-content-center mt-12"
                                                >
                                                     <i className="ph ph-shopping-cart" /> Сагсанд нэмэх
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                            <div class="section-heading">
                                <div class="flex-between flex-wrap gap-8">
                                    <h5 class="mb-0"></h5>
                                    <Link to="/shop"
                                        className="mt-16 btn btn-main fw-medium d-inline-flex align-items-center rounded-pill gap-8"
                                        tabIndex={0}
                                    >
                                        Бүх эрэлттэй барааг үзэх
                                        <span className="icon text-xl d-flex">
                                            <i className="ph ph-arrow-right" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>

    )
}

export default Recommended