import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Checkbox } from 'primereact/checkbox';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import 'primeicons/primeicons.css';

const Account = () => {
    const [userName, setUserName] = useState(localStorage.getItem('userName'));
    const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem('phoneNumber'));
    const [regNum, setRegNum] = useState(localStorage.getItem('regNum'));
    const [userSoum, setUserSoum] = useState(localStorage.getItem('userSoumId'));
    const [userCity, setUserCity] = useState(localStorage.getItem('userCityId'));
    const [userFB, setUserFB] = useState(localStorage.getItem('userFB'));
    const [userPass, setUserPass] = useState(localStorage.getItem('userPass'));
    const [cityList, setCityList] = useState([]);
    const [soumList, setSoumList] = useState([]);   
    const [isEdit, setIsEdit] = useState(false);  
    const [newPass, setNewPass] = useState("");  
    const [newPassConfirm, setNewPassConfirm] = useState("");  
    const [changePass, setChangePass] = useState(false);

    const getSoumList = async($cityId) => {
        setUserCity($cityId);
        try {
            const response = await axios({
                url: process.env.REACT_APP_API_URL + "/api/district",
                headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
                data: JSON.stringify({ city_id: $cityId }),
                method: "POST"
            });
            if (response) {
                setSoumList(response.data.district_info);
            }
        } catch (error) {
            toast.error('Сүлжээний алдаа гарлаа!' + error, { autoClose: 3500 });
        }
    }
    
    const updateUser = async(event) => {
        event.preventDefault();

        if(newPass == "" || newPassConfirm == "")
            toast.error('Шинэ ууц үгээ оруулна уу!', { autoClose: 1500 });

        else if(newPass != newPassConfirm)
            toast.error('Нууц үг тохирохгүй байна!', { autoClose: 1500 });
        
        else {
            try {
                const response = await axios({
                    url: process.env.REACT_APP_API_URL + "/api/user/update",
                    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
                    data: JSON.stringify({
                        user_id:  localStorage.getItem('userId'),
                        cust_name: userName,
                        prov_id: userCity,
                        soum_id: userSoum,
                        cust_phone: phoneNumber,
                        cust_regNum: regNum,
                        cust_fb: userFB,
                        cust_pass: changePass ?  newPass : userPass
                    }),
                    method: "POST"
                });
                if (response) {
                    toast.success('Мэдээлэл амжилттай шинэчлэгдлээ!', { autoClose: 1500 });
                    window.location.reload();
                }
            } catch (error) {
                toast.error('Сүлжээний алдаа гарлаа!' + error, { autoClose: 1500 });
            }
        }
    }
    
    useEffect(() => {
        let isMountedCity = true;

        const fetchCityList = async () => {
            try {
                const response = await axios({
                    url: process.env.REACT_APP_API_URL + "/api/city/all",
                    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
                    method: "GET"
                });
                if (isMountedCity) {
                    setCityList(response.data.city_info);
                }
            } catch (error) {
                if (isMountedCity)
                    toast.error('Сүлжээний алдаа гарлаа!' + error, {autoClose: 3500});
            }
        };

        fetchCityList();
        userCity && getSoumList(userCity);
        
        return () => {
            isMountedCity = false;
        };
    }, []);

    return (
        <section className="contact py-40">
            <ToastContainer />
            <div className="container container-lg">
                <div className="row gy-5">
                    <div className="col-lg-12">
                        <div className="contact-box border border-gray-100 rounded-16 px-30 py-30">
                            <form>
                                <div class="flex-between flex-wrap gap-8">
                                    <h6 className="mb-32 text-xl">Хувийн мэдээлэл</h6>
                                    <div className="flex-align gap-16 mb-32">
                                        <button
                                            className="btn btn-main"
                                            onClick={() => setIsEdit(true)}
                                            disabled={isEdit ?  true : false}>
                                            <i className='pi pi-user-edit text-sm'/>
                                        </button>
                                        <button type="submit" 
                                            className="btn btn-success ml-20"
                                            onClick={(e) => updateUser(e)}
                                            disabled={isEdit ?  false : true}>
                                            <i className='pi pi-save text-sm'/>
                                        </button>
                                    </div>
                                </div>
                                <div className="row gy-4">
                                    <div className="col-sm-4 col-xs-6">
                                        <label
                                            className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4">
                                            Нэр
                                            <span className="text-danger text-xl line-height-1">*</span>
                                        </label>
                                        <input type="text" required
                                            className="common-input px-16 text-sm"
                                            value={userName}
                                            readOnly={isEdit ? false : true}
                                            onChange={(e) => setUserName(e.target.value)} />
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <label
                                            className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4">
                                            Утасны дугаар
                                            <span className="text-danger text-xl line-height-1">*</span>
                                        </label>
                                        <input
                                            type="text" required
                                            maxLength={8}
                                            className="common-input px-16 text-sm"
                                            value={phoneNumber}
                                            readOnly={isEdit ? false : true}
                                            onChange={(e) => setPhoneNumber(e.target.value)} />
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <label
                                            className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4">
                                            Регистрийн дугаар
                                            <span className="text-danger text-xl line-height-1">*</span>
                                        </label>
                                        <input
                                            type="text" required
                                            className="common-input px-16 text-sm"
                                            value={regNum}
                                            maxLength={10}
                                            readOnly={isEdit ? false : true}
                                            onChange={(e) => setRegNum(e.target.value)} />
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <label
                                            className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4">
                                            Фэйсбүүк хаяг
                                            <span className="text-danger text-xl line-height-1">*</span>
                                        </label>
                                        <input
                                            type="text" required
                                            className="common-input px-16 text-sm"
                                            value={userFB}
                                            readOnly={isEdit ? false : true}
                                            onChange={(e) => setUserFB(e.target.value)} />
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <label
                                            className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4">
                                            Аймаг
                                            <span className="text-danger text-xl line-height-1">*</span>
                                        </label>
                                        <select className="common-input px-16 text-sm" required
                                            value={userCity} 
                                            disabled={isEdit ? false : true}
                                            onChange={(e) => getSoumList(e.target.value)}>
                                            {
                                                cityList.map((city) => (
                                                    <option value={city.id} selected={city.id == userCity ? true : false}>{city.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <label
                                            className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4">
                                            Сум
                                            <span className="text-danger text-xl line-height-1">*</span>
                                        </label>
                                        <select className="common-input px-16" required
                                            value={userSoum} 
                                            disabled={isEdit ? false : true}
                                            onChange={(e) => setUserSoum(e.target.value)}>
                                            {
                                                soumList.map((soum) => (
                                                    <option value={soum.id}>{soum.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-sm-12">
                                        <label
                                            className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4">
                                            Нууц үг солих эсэх
                                        </label>
                                        <Checkbox value={true} inputId="passYes" className='mt-10'
                                            onChange={(e) => setChangePass(e.target.value)} 
                                            checked={changePass == true} disabled={isEdit == false}/>
                                        <label htmlFor="passYes" className='text-sm text-gray-900'>&nbsp;Тийм</label>
                                        <Checkbox value={false} inputId="passNo" className='mt-10'
                                            onChange={(e) => setChangePass(e.target.value)} disabled={isEdit == false}
                                            checked={changePass == false} style={{marginLeft: '10px'}}/>
                                        <label htmlFor="passNo" className='text-sm text-gray-900'>&nbsp;Үгүй</label>
                                    </div>
                                    {
                                        changePass && (
                                        <>
                                            <div className="col-sm-4 col-xs-6">
                                                <label
                                                    className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4">
                                                    Хуучин нууц үг
                                                </label>
                                                <input type="password" className="common-input px-16 text-sm" value={userPass} />
                                            </div>
                                            <div className="col-sm-4 col-xs-6">
                                                <label
                                                    className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4">
                                                    Шинэ нууц үг
                                                    <span className="text-danger text-xl line-height-1">*</span>
                                                </label>
                                                <input
                                                    type="password" 
                                                    required = {changePass == true ? true : false}
                                                    className="common-input px-16 text-sm"
                                                    value={newPass}
                                                    onChange={(e) => setNewPass(e.target.value)} />
                                            </div>
                                            <div className="col-sm-4 col-xs-6">
                                                <label
                                                    className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4">
                                                    Шинэ нууц үг давтан оруулах
                                                    <span className="text-danger text-xl line-height-1">*</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    required = {changePass == true ? true : false}
                                                    className="common-input px-16 text-sm"
                                                    value={newPassConfirm}
                                                    maxLength={10}
                                                    onChange={(e) => setNewPassConfirm(e.target.value)} />
                                            </div>
                                        </>
                                        )
                                    }
                                </div>
                            </form>
                        </div>
                    </div>  
                </div>
            </div>
        </section>
    )
}

export default Account