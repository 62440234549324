import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import axios from "axios";

import 'primeicons/primeicons.css';
import 'react-toastify/dist/ReactToastify.css';

const Invoice = () => {
    const [visible, setVisible] = useState(false);
    const [invoiceList, setInvoiceList] = useState([]);
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalOrder, setTotalOrder] = useState(0);
    const [invNumber, setInvNumber] = useState(0);

    const showDialog = async(inv_number) =>
    {
        setInvNumber(inv_number);
        try {
            const response = await axios({
                url: process.env.REACT_APP_API_URL + "/api/invoice/item",
                headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
                data: JSON.stringify({ inv_number : inv_number }),
                method: "POST"
            });
            if (response) {
                setInvoiceItems(response.data.invoice_items);
                setTotalItems(response.data.totalItems);
            }
        } catch (error) {
            toast.error('Сүлжээний алдаа гарлаа!' + error, {autoClose: 3500});
        }
        setVisible(true);
    }

    useEffect(() => {
        let isMounted = true;

        const fetchAllInvoices = async () => {
            try {
                const response = await axios({
                    url: process.env.REACT_APP_API_URL + "/api/invoice/user",
                    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
                    data: JSON.stringify({ inv_user : localStorage.getItem('userId') }),
                    method: "POST"
                });
                if (isMounted) {
                    setInvoiceList(response.data.invoice_info);
                    setTotalElements(response.data.totalItems);

                    const newTotalPrice = response.data.invoice_info.reduce((acc, item) => acc + Number(item.inv_price), 0);
                    setTotalPrice(newTotalPrice);
                    const newTotalOrder = response.data.invoice_info.reduce((acc, item) => acc + Number(item.inv_items_cnt), 0);
                    setTotalOrder(newTotalOrder);
                }
            } catch (error) {
                if (isMounted)
                    toast.error('Сүлжээний алдаа гарлаа!' + error, {autoClose: 3500});
            }
        };

        fetchAllInvoices();
        
        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <section className="cart py-40">
            <ToastContainer />
            <div className="container container-lg">
                <div className="row gy-4">
                    <div className="col-xl-9 col-lg-8">
                        <div className="cart-table border border-gray-100 rounded-8 px-20 py-28">
                            <div className="overflow-x-auto scroll-sm scroll-sm-horizontal">
                                <table className="table style-one">
                                    <thead>
                                        <tr>
                                            <th className="text-sm fw-medium">№</th>
                                            <th className="text-sm fw-medium">Нэхэмжлэлийн дугаар</th>
                                            <th className="text-sm fw-medium">Барааны тоо</th>
                                            <th className="text-sm fw-medium">Нийт үнэ</th>
                                            <th className="text-sm fw-medium">Нэхэмжилсэн огноо</th>
                                            <th className="text-sm fw-medium">Харах</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (invoiceList || []).length > 0 ? (invoiceList || []).map((item, index) => (
                                                <tr>
                                                    <td className="text-center">
                                                        <span className="text-sm">{index + 1}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <span className="text-sm">{item.inv_number}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <span className="text-sm">{item.inv_items_cnt}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <span className="text-sm">{Number(item.inv_price).toLocaleString() +'₮'}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <span className="text-sm">{item.inv_date}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <button type="button" onClick={() => showDialog(item.inv_number)}>
                                                            <i className="pi pi-list text-md d-flex text-main-800 text-center" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )) :
                                            (
                                                <tr>
                                                    <td colSpan={6} className="text-center">
                                                        <span className="text-md mb-0">Танд одоогоор үүссэн нэхэмжлэл байхгүй байна!</span>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Dialog 
                        visible={visible} maximizable 
                        style={{ width: '70vw' }} 
                        header={<div style={{ padding: '15px', fontSize: '2.2vh'}}>Нэхэмжлэлийн № {invNumber} ({totalItems} бараа)</div>} 
                        onHide={() => {if (!visible) return; setVisible(false); }}>
                        <div className="col-xl-12 col-lg-8">
                            <div className="cart-table border border-gray-100 rounded-8 px-20 py-28">
                                <div className="overflow-x-auto scroll-sm scroll-sm-horizontal">
                                    <table className="table style-two">
                                        <thead>
                                            <tr>
                                                <th className="text-sm fw-medium">№</th>
                                                <th className="text-sm fw-medium">Барааны зураг</th>
                                                <th className="text-sm fw-medium">Барааны код</th>
                                                <th className="text-sm fw-medium">Тоо ширхэг</th>
                                                <th className="text-sm fw-medium">Нийт үнэ</th>
                                                <th className="text-sm fw-medium">Нэхэмжилсэн огноо</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (invoiceItems || []).length > 0 ? (invoiceItems || []).map((item, index) => (
                                                    <tr>
                                                        <td className="text-center">
                                                            <span className="text-sm">{index + 1}</span>
                                                        </td>
                                                        <td className="text-center">
                                                            <span className="text-sm"><img src={'https://ingumel.mn/backend/' + item.product_image} style={{height: '65px'}}/></span>
                                                        </td>
                                                        <td className="text-center">
                                                            <span className="text-sm">{item.product_barCode}</span>
                                                        </td>
                                                        <td className="text-center">
                                                            <span className="text-sm">{item.product_qty}</span>
                                                        </td>
                                                        <td className="text-center">
                                                            <span className="text-sm">{Number(item.product_price).toLocaleString() +'₮'}</span>
                                                        </td>
                                                        <td className="text-center">
                                                            <span className="text-sm">{item.invoice_date}</span>
                                                        </td>
                                                    </tr>
                                                )) :
                                                (
                                                    <tr>
                                                        <td colSpan={5} className="text-center">
                                                            <span className="text-md mb-0">Нэхэмжлэгдсэн барааны мэдээлэл олдсонгүй!</span>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <div className="col-xl-3 col-lg-4">
                        <div className="cart-sidebar border border-gray-100 rounded-8 px-24 py-10 text-center">
                            <h6 className="text-md mb-20 fw-medium">Нийт нэхэмжлэлийн тоо</h6>
                            <div className="bg-color-three rounded-8 p-10 mt-20 text-center">
                                <span className="text-main-600 text-lg fw-medium">{totalElements}</span>
                            </div>
                            <h6 className="text-md mb-20 fw-medium mt-20">Нийт худалдан авалтын дүн</h6>
                            <div className="bg-color-three rounded-8 p-10 mt-20 text-center">
                                <span className="text-main-600 text-lg fw-medium">{Number(totalPrice).toLocaleString() +'₮'}</span>
                            </div>
                            <h6 className="text-md mb-20 fw-medium mt-20">Худалдан авалт хийсэн <br/>нийт барааны тоо</h6>
                            <div className="bg-color-three rounded-8 p-10 mt-20 text-center">
                                <span className="text-main-600 text-lg fw-medium">{totalOrder}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Invoice