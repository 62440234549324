import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import InnerImageZoom from 'react-inner-image-zoom';
import Slider from 'react-slick';
import axios from "axios";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import 'react-toastify/dist/ReactToastify.css';

const ProductDetails = ({ addToCartHeader }) => {
    const { productCode } = useParams();
    const [productInfo, setProductInfo] = useState();
    const [proQty, setProQty] = useState(0);
    const [exchRate, setExchRate] = useState(0);
    const [relatedProducts, setRelatedProducts] = useState([]);

    const incrementQuantity = () => {
        const currentQuantity = Number(proQty); 
        const orderCount = Number(productInfo.order_cnt) || 0;
        setProQty(currentQuantity + orderCount);
    }
    const decrementQuantity = () => setProQty(proQty > productInfo.order_cnt ? proQty - productInfo.order_cnt : productInfo.order_cnt);

    const addToCart = (item) => {
        let cart = JSON.parse(localStorage.getItem('cart')) || [];
    
        const existingItemIndex = cart.findIndex(cartItem => cartItem.id === item.id);
        
        if (existingItemIndex > -1) {
            cart[existingItemIndex].quantity += proQty;
        } else {
            item.quantity = proQty;
            cart.push(item);
        }
    
        localStorage.setItem('cart', JSON.stringify(cart));
        localStorage.setItem('cartLength', cart.length);
        localStorage.setItem('excRate', exchRate);
        toast.success(productInfo.pro_name +'-с '+proQty + 'ширхэг сагсанд нэмэгдлээ!', {autoClose: 1500});
        addToCartHeader();
        return cart;
    };

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return (
            <button
                type="button" onClick={onClick}
                className={` ${className} slick-next slick-arrow flex-center rounded-circle border border-gray-100 hover-border-main-600 text-xl hover-bg-main-600 hover-text-white transition-1`}
            >
                <i className="ph ph-caret-right" />
            </button>
        );
    }

    function SamplePrevArrow(props) {
        const { className, onClick } = props;

        return (

            <button
                type="button"
                onClick={onClick}
                className={`${className} slick-prev slick-arrow flex-center rounded-circle border border-gray-100 hover-border-main-600 text-xl hover-bg-main-600 hover-text-white transition-1`}
            >
                <i className="ph ph-caret-left" />
            </button>
        );
    }

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1599,
                settings: {
                    slidesToShow: 6,

                },
            },
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 4,

                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,

                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,

                },
            },
            {
                breakpoint: 424,
                settings: {
                    slidesToShow: 1,

                },
            },

        ],
    };

    useEffect(() => {
        let isMounted = true;
        let isMountedRelated = true;

        const fetchRelatedProducts = async () => {
            try {
                const response = await axios({
                    url: process.env.REACT_APP_API_URL + "/api/product/col",
                    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
                    data: JSON.stringify({ col : productInfo && productInfo.pro_category }),
                    method: "POST"
                });
                if (isMountedRelated) {
                    setRelatedProducts(response.data.product_info);
                }
            } catch (error) {
                if (isMountedRelated)
                    toast.error('Сүлжээний алдаа гарлаа!' + error, {autoClose: 1500});
            }
        };

        const fetchProductInfo = async () => {
            try {
                const response = await axios({
                    url: process.env.REACT_APP_API_URL + "/api/product/detail",
                    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
                    data: JSON.stringify({ pro_id: null, pro_code: productCode }),
                    method: "POST"
                });
                if (isMounted) {
                    setProductInfo(response.data.product_info);
                    setExchRate(response.data.exchangeRate);
                    setProQty(response.data.product_info.order_cnt);
                    fetchRelatedProducts();
                }
            } catch (error) {
                if (isMounted)
                    toast.error('Сүлжээний алдаа гарлаа!' + error, {autoClose: 1500});
            }
        };

        fetchProductInfo();
        
        return () => {
            isMounted = false;
            isMountedRelated = false;
        };
    }, [productCode]);

    // const settingsThumbs = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     focusOnSelect: true,
    // };

    return (
        <>
        <section className="product-details py-40">
            <ToastContainer />
            {
                <div className="container container-lg">
                    <div className="row gy-4">
                        <div className="col-xl-9">
                            <div className="row gy-4">
                                <div className="col-xl-6">
                                    <div className="product-details">
                                        <div className="product-details__thumb-slider border border-gray-100 rounded-16">
                                            <div className="product-details__thumb flex-center">
                                                <InnerImageZoom src={productInfo && 'https://ingumel.mn/backend/' + productInfo.pro_image} className='max-h-400 rounded-8' />
                                            </div>
                                        </div>
                                        {/* <div className="mt-24">
                                            <div className="product-details__images-slider">
                                                <Slider {...settingsThumbs}>
                                                    {productImages.map((image, index) => (
                                                        <div className="center max-w-120 max-h-120 h-100 flex-center border border-gray-100 rounded-16 p-8" key={index} onClick={() => setMainImage(image)}>
                                                            <img className='thum' src={image} alt={`Thumbnail ${index}`} />
                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="product-details__content">
                                        <h5 className="mb-12 text-xl">{productInfo && productInfo.pro_name}</h5>
                                        <div className="flex-align flex-wrap gap-12">
                                            <span className="text-gray-500">
                                                <span className="text-gray-900">Барааны код:&nbsp;</span>{productInfo && productInfo.pro_code}
                                            </span>
                                            <span className="text-sm fw-medium text-gray-500">|</span>
                                            <span className="text-gray-500">
                                                <span className="text-gray-900">Барааны баркод:&nbsp;</span>{productInfo && productInfo.pro_barCode ? productInfo.pro_barCode : '-'}
                                            </span>
                                        </div>
                                        <span className="mt-32 pt-32 text-gray-700 border-top border-gray-100 d-block" />
                                        <div className="mb-40">
                                            <h6 className="mb-24 text-lg">Барааны танилцуулга</h6>
                                            <ul className="mt-32">
                                                <li className="text-gray-400 mb-14 flex-align gap-14">
                                                    <span className="w-20 h-20 bg-main-50 text-gray-500 text-xs flex-center rounded-circle">
                                                        <i className="ph ph-check" />
                                                    </span>
                                                    <span className="text-heading fw-medium">
                                                        Ангилал:&nbsp;<span className="text-gray-500">{productInfo && productInfo.cat_name ? productInfo.cat_name : '-'}</span>
                                                    </span>
                                                </li>
                                                <li className="text-gray-400 mb-14 flex-align gap-14">
                                                    <span className="w-20 h-20 bg-main-50 text-gray-500 text-xs flex-center rounded-circle">
                                                        <i className="ph ph-check" />
                                                    </span>
                                                    <span className="text-heading fw-medium">
                                                        Өнгөний сонголт:&nbsp;<span className="text-gray-500">{productInfo && productInfo.pro_color ? productInfo.pro_color : '-'}</span>
                                                    </span>
                                                </li>
                                                <li className="text-gray-400 mb-14 flex-align gap-14">
                                                    <span className="w-20 h-20 bg-main-50 text-gray-500 text-xs flex-center rounded-circle">
                                                        <i className="ph ph-check" />
                                                    </span>
                                                    <span className="text-heading fw-medium">
                                                        Размер:&nbsp;<span className="text-gray-500">{productInfo && productInfo.pro_size ? productInfo.pro_size : '-'}</span>
                                                    </span>
                                                </li>
                                                <li className="text-gray-400 mb-14 flex-align gap-14">
                                                    <span className="w-20 h-20 bg-main-50 text-gray-500 text-xs flex-center rounded-circle">
                                                        <i className="ph ph-check" />
                                                    </span>
                                                    <span className="text-heading fw-medium">
                                                        Хайрцаг доторх тоо:&nbsp;<span className="text-gray-500">{productInfo && productInfo.pro_package_cnt ? productInfo.pro_package_cnt : '-'}</span>
                                                    </span>
                                                </li>
                                                <li className="text-gray-400 mb-14 flex-align gap-14">
                                                    <span className="w-20 h-20 bg-main-50 text-gray-500 text-xs flex-center rounded-circle">
                                                        <i className="ph ph-check" />
                                                    </span>
                                                    <span className="text-heading fw-medium">
                                                        Захиалах хамгийн доод тоо:&nbsp;<span className="text-gray-500">{productInfo && productInfo.order_cnt ? productInfo.order_cnt : '-'}</span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="product-details__sidebar py-40 px-32 border border-gray-100 rounded-16">
                                <div className="mb-32">
                                    <label
                                        htmlFor="stock"
                                        className="text-md mb-8 text-heading fw-semibold d-block">
                                        Тоо ширхэг:
                                    </label>
                                    <div className="d-flex rounded-4 overflow-hidden">
                                        <button onClick={decrementQuantity} type="button"
                                            className="quantity__minus flex-shrink-0 h-48 w-48 text-neutral-600 bg-gray-50 flex-center hover-bg-main-600 hover-text-white"
                                        >
                                            <i className="ph ph-minus" />
                                        </button>
                                        <input
                                            type="number"
                                            className="quantity__input flex-grow-1 border border-gray-100 border-start-0 border-end-0 text-center w-32 px-16"
                                            id="stock"
                                            value={proQty} readOnly/>
                                        <button onClick={incrementQuantity} type="button"
                                            className="quantity__plus flex-shrink-0 h-48 w-48 text-neutral-600 bg-gray-50 flex-center hover-bg-main-600 hover-text-white"
                                        >
                                            <i className="ph ph-plus" />
                                        </button>
                                    </div>
                                </div>
                                <div className="mb-32">
                                    <div className="flex-between flex-wrap gap-8 border-bottom border-gray-100 pb-16 mb-16">
                                        <span className="text-gray-500 text-sm">Нэгж үнэ:</span>
                                        <h6 className="text-md mb-0">¥{productInfo && productInfo.pro_price}</h6>
                                        <span className="text-gray-500 fw-normal text-sm"> / {productInfo && productInfo.pro_price * parseInt(exchRate)}₮</span>{" "}

                                    </div>
                                    <div className="flex-between flex-wrap gap-8">
                                        <span className="text-gray-500 text-sm">Нийт үнэ:</span>
                                        <h6 className="text-md mb-0">¥{productInfo && productInfo.pro_price * proQty}</h6>
                                        <span className="text-gray-500 fw-normal text-sm"> / {productInfo && (productInfo.pro_price * proQty * parseInt(exchRate))}₮</span>{" "}
                                    </div>
                                </div>
                                <Link onClick={() => addToCart(productInfo && productInfo)} className="btn btn-main flex-center gap-8 rounded-8 py-16 fw-normal mt-48">
                                    <i className="ph ph-shopping-cart-simple text-md" />Сагсанд нэмэх
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </section>
        
        <section className="new-arrival py-40">
            <div className="container container-lg">
                <div className="section-heading">
                    <div className="flex-between flex-wrap gap-8">
                        <h5 className="mb-0 text-xl">Төстэй бараанууд</h5>
                        <div className="flex-align mr-point gap-16">
                            {
                                productInfo && (
                                    <Link to={"/shop/" + productInfo.pro_category}
                                        className="text-sm fw-medium text-gray-700 hover-text-main-600">
                                        Бүх төстэй бараануудыг харах
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="new-arrival__slider arrow-style">
                    <Slider {...settings}>
                        {
                            relatedProducts && relatedProducts.map((pro) => (
                                <div className="vendors-two-item rounded-12 overflow-hidden bg-color-three border border-neutral-50 hover-border-main-600 transition-2">
                                    <div className='vendors-two-item__top bg-overlay style-two position-relative'>
                                        <div className='vendors-two-item__thumbs' style={{height: '13rem'}}>
                                            {/* <img src='https://react.marketpro.wowtheme7.com/assets/images/thumbs/vendors-two-img1.png' className="cover-img"/> */}
                                            <img src={'https://ingumel.mn/backend/' + pro.pro_image} className="cover-img"/>
                                        </div>
                                        <div className='position-absolute top-0 inset-inline-start-0 w-100 h-100 p-24 z-1 d-flex flex-column justify-content-between'>
                                            <div className='d-flex align-items-center justify-content-between'></div>
                                            <div className='mt-16'>
                                                <h6 className='text-white fw-semibold mb-12'>
                                                    <Link to={"/product/" + pro.pro_code} className="text-line-2 text-md hover-text-white">
                                                        {pro.pro_name}
                                                    </Link>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vendors-two-item__content p-24 flex-grow-1">
                                        <div className="d-flex flex-column gap-14">
                                            <div className="flex-align gap-8">
                                                <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                    <i className="pi pi-th-large" />
                                                </span>
                                                <span className="text-sm text-gray-900">
                                                    {pro.cat_name}
                                                </span>
                                            </div>
                                            <div className="flex-align gap-8">
                                                <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                    <i className="pi pi-hashtag" />
                                                </span>
                                                <span className="text-sm text-gray-900">
                                                    Код: {pro.pro_code}
                                                </span>
                                            </div>
                                            <div className="flex-align gap-8">
                                                <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                    <i className="pi pi-money-bill" />
                                                </span>
                                                <span className="text-sm text-gray-900">
                                                    Үнэ: ¥{pro.pro_price}
                                                    <span>&nbsp;/&nbsp;{pro.pro_price * parseInt(exchRate)}₮</span>{" "}
                                                </span>
                                            </div>
                                        </div>
                                        {/* <Link to={"/product/" + pro.pro_code}
                                            className="btn bg-main-600 hover-bg-main-700 text-white py-12 px-24 rounded-8 flex-center gap-8 fw-medium mt-24">
                                            <span className="text-xl d-flex text-white">
                                                <i className="pi pi-list" />
                                            </span>
                                        </Link> */}
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </section>
        </>
    )
}

export default ProductDetails