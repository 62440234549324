import { useEffect, useState } from "react";

const Preloader = () => {
  let [active, setActive] = useState(true);
  
  useEffect(() => {
    const timer = setTimeout(() => setActive(false), 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {
        active ? 
        (
          <div className="preloader">
            <img src="../../assets/images/icon/preloader.gif" />
          </div>
        ) 
        : 
        (<div></div>)
      }
    </>
  );
};

export default Preloader;