import React, {useState, useContext, useEffect } from "react";
import { AuthContext } from '../store/auth-context';
import { PhoneInput } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";

import 'react-international-phone/style.css';
import 'react-toastify/dist/ReactToastify.css';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } 
    catch (error) {
        return false;
    }
};

const Login = () => {
    const {login } = useContext(AuthContext);
    const [phone, setPhone] = useState('');
    const [userPass, setUserPass] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const countrySelectorStyleProps = { buttonStyle: { height: '50px' } };
    const isValid = isPhoneValid(phone);

    const submitHandler = async (event) => {
        event.preventDefault();
        
        if (rememberMe || localStorage.rememberMe) {
            localStorage.setItem('userPhone', phone);
            localStorage.setItem('password', userPass);
            localStorage.setItem('rememberMe', rememberMe);
        } else {
            localStorage.removeItem('userPhone');
            localStorage.removeItem('password');
            localStorage.removeItem('rememberMe');
        }

        setPhone('');
        setUserPass('');
        setRememberMe('');

        let promise = await new Promise((resolve) => {
            axios({
                url: process.env.REACT_APP_API_URL + "/api/user/login",
                headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
                data: JSON.stringify({ user_phone: phone.substr(4), user_pass: userPass }),
                method: "POST"
            })
            .then((response) => {
                if (response.data.status == 200) {
                    login(response.data.user_info.cust_name, response.data.user_info.cust_phone, response.data.user_info.id);
                    localStorage.setItem('userName', response.data.user_info.cust_name);
                    localStorage.setItem('phoneNumber', response.data.user_info.cust_phone);
                    localStorage.setItem('regNum', response.data.user_info.cust_regNum);
                    localStorage.setItem('userId', response.data.user_info.id);
                    localStorage.setItem('userSoumName', response.data.user_info.soum_name);
                    localStorage.setItem('userCityName', response.data.user_info.city_name);
                    localStorage.setItem('userSoumId', response.data.user_info.soum_id);
                    localStorage.setItem('userCityId', response.data.user_info.prov_id);
                    localStorage.setItem('userFB', response.data.user_info.cust_fb);
                    localStorage.setItem('userPass', response.data.user_info.cust_pass);
                    resolve(true);
                } else {
                    toast.error(response.data.message, {autoClose: 3500});
                    resolve(false);
                }
            })
            .catch((error) => {
                toast.error('Сүлжээний алдаа гарлаа!' + error, {autoClose: 3500});
                resolve(false);
            });
          });
      
        if (promise)
            window.location.reload(); 
    };

    useEffect(() => {
        const savedUserPhone = localStorage.getItem('userPhone');
        const savedPassword = localStorage.getItem('password');

        if (savedUserPhone) {
            setPhone(savedUserPhone);
        }
        if (savedPassword) {
            setUserPass(savedPassword);
        }
    }, []);


    return (
        <section className="account py-80">
            <ToastContainer />
            <div className="container container-lg">
                <form>
                    <div className="row gy-4">
                        {/* Login Card Start */}
                        <div className="col-xl-6 pe-xl-5">
                            <div className="border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40 h-100">
                                <h6 className="text-lg mb-16">Нэвтрэх</h6>
                                <span className="mb-32 text-justify text-sm">Та захиалга өгөх, барааны жагсаалт харахын тулд өөрийн эрхээр нэвтэрч орсон байх ёстойг анхаарна уу.</span>
                                <div className="mb-15">
                                    <label className="text-neutral-900 text-sm mb-8 fw-medium">
                                        Утасны дугаар <span className="text-danger">*</span>{" "}
                                    </label>
                                    <PhoneInput 
                                        defaultCountry="mn" value={phone} 
                                        onChange={(phone) => setPhone(phone)} 
                                        inputClass="my-input-class" 
                                        countrySelectorStyleProps={countrySelectorStyleProps}
                                        countrySelectorStyle={{ height: '50px' }}
                                        inputStyle={{ height: '50px', width: '100%' }} />
                                    <span className="ms-login-wrap ms-login-fp" style={{marginTop: 0}}>
                                        {!isValid && phone != '+976' && <label><a className="text-sm" style={{ color: 'red', fontStyle: 'italic', marginTop: 0 }}>Утасны дугаар буруу байна!</a></label>}
                                    </span>
                                </div>
                                <div className="mb-20">
                                    <label htmlFor="password" className="text-neutral-900 text-sm mb-8 fw-medium">
                                        Нууц үг <span className="text-danger">*</span>{" "}
                                    </label>
                                    <div className="position-relative">
                                        <input 
                                            value={userPass} className="common-input"
                                            onChange={(e) => setUserPass(e.target.value)} 
                                            type="password" name="password" id="password"
                                            placeholder="Нууц үгээ оруулна уу..." required />
                                        <span
                                            className="toggle-password position-absolute top-50 inset-inline-end-0 me-16 translate-middle-y cursor-pointer ph ph-eye-slash"
                                            id="#password"
                                        />
                                    </div>
                                </div>
                                <div className="mb-20 mt-30">
                                    <div className="flex-align gap-48 flex-wrap">
                                        <div className="form-check common-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={localStorage.rememberMe ? localStorage.rememberMe : rememberMe}
                                                onChange={(e) => setRememberMe(e.target.checked)}/>
                                            <label className="form-check-label flex-grow-1 text-sm">
                                                Нэвтрэх мэдээлэл сануулах
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-40">
                                    <div className="flex-align gap-48 flex-wrap">
                                        <button
                                            type="submit"
                                            className="btn btn-main py-18 px-40 text-sm"
                                            disabled={(phone == '+976' || userPass == '') ? true : false} onClick={submitHandler}>
                                            Нэвтрэх
                                        </button>
                                    </div>
                                </div>
                                {/* <div className="mt-48">
                                    <Link to="#" className="text-danger-600 text-sm fw-semibold hover-text-decoration-underline text-italic">
                                        Нууц үгээ мартсан уу?
                                    </Link>
                                </div> */}
                            </div>
                        </div>
                        {/* Login Card End */}
                        {/* Register Card Start */}
                        <div className="col-xl-6">
                            <div className="border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40">
                                <img src="../../assets/images/bg/login.png" />
                            </div>
                        </div>
                        {/* Register Card End */}
                    </div>
                </form>
            </div>
        </section>

    )
}

export default Login