import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../store/auth-context';
import { ToastContainer, toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
        
import 'primereact/resources/themes/lara-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'react-toastify/dist/ReactToastify.css';

const Header = ({ cartCount }) => {
    const { logout } = useContext(AuthContext);
    const [scroll, setScroll] = useState(false)
    const [visible, setVisible] = useState(false);
    const [categories, setCategories] = useState([]);
    const [childCategories, setChildCategories] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [cartItems, setCartItems] = useState(0);

    const confirm = () => {
        logout();
        setVisible(false);
        window.location.reload(); 
    };
    
    const cancel = () => setVisible(false);

    useEffect(() => {
        let isMounted = true;
        const fetchCategories = async () => {
            try {
                const response = await axios({
                    url: process.env.REACT_APP_API_URL + "/api/category/all",
                    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
                    method: "GET"
                });
                if (isMounted) {
                    const parentCategories = response.data.category_info.filter(cat => cat.parent_id == 0);
                    setCategories(parentCategories);
                    setTotalElements(response.data.totalElements);

                    const childCategories = response.data.category_info.filter(cat => cat.parent_id != 0);
                    setChildCategories(childCategories);
                    // setCategories(response.data.category_info);
                }
            } catch (error) {
                if (isMounted)
                    toast.error('Сүлжээний алдаа гарлаа!' + error, {autoClose: 3500});
            }
        };

        const handleScroll = () => {
            if (window.pageYOffset < 150) {
                setScroll(false);
            } else if (window.pageYOffset > 150) {
                setScroll(true);
            }
        };
        
        localStorage.getItem('cart') && setCartItems(JSON.parse(localStorage.getItem('cart')).length) ;

        fetchCategories();

        window.addEventListener('scroll', handleScroll);
        
        return () => {
            isMounted = false;
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Set the default language
    // const [selectedLanguage, setSelectedLanguage] = useState("Eng");
    // const handleLanguageChange = (language) => {
    //     setSelectedLanguage(language);
    // };

    // Mobile menu support
    const [menuActive, setMenuActive] = useState(false)
    const [activeIndex, setActiveIndex] = useState(null);
    const handleMenuClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    const handleMenuToggle = () => {
        setMenuActive(!menuActive);
    };

    // Search control support
    const [activeSearch, setActiveSearch] = useState(false)
    const handleSearchToggle = () => {
        setActiveSearch(!activeSearch);
    };

    // category control support
    const [activeCategory, setActiveCategory] = useState(false)
    const handleCategoryToggle = () => {
        setActiveCategory(!activeCategory);
    };
    const [activeIndexCat, setActiveIndexCat] = useState(null);
    const handleCatClick = (index) => {
        setActiveIndexCat(activeIndexCat === index ? null : index);
    };

    return (
        <>
            {/* <div className="overlay" /> */}
            <ToastContainer />
            {/* <div className={`side-overlay ${(menuActive || activeCategory) && "show"}`} /> */}
            {/* ==================== Mobile Menu Start Here ==================== */}
            <div className={`mobile-menu scroll-sm d-lg-none d-block ${menuActive && "active"}`} >
                <button onClick={() => { handleMenuToggle(); setActiveIndex(null) }} type="button" className="close-button bg-main-300">
                    <i className="ph ph-x text-white" />{" "}
                </button>
                <div className="mobile-menu__inner">
                    <Link to="/" className="mobile-menu__logo">
                        <img src="../../assets/images/logo/logo.png" />
                    </Link>
                    <div className="mobile-menu__menu">
                        <ul className="nav-menu flex-align nav-menu--mobile">
                            <li className="nav-menu__item">
                                <Link to="#" className="nav-menu__link text-center">
                                    <i className="pi pi-user text-2xl text-main" /><br/>
                                    <span className="text-md text-gray-500">{localStorage.userName}</span>
                                </Link>
                                <Link to="#" className="nav-menu__link text-center">
                                    <i className="pi pi-phone text-2xl text-main" /><br/>
                                    <span className="text-md text-gray-500">{localStorage.userPhone}</span>
                                </Link>
                                <Link to="#" className="nav-menu__link text-center">
                                    <i className="pi pi-info-circle text-2xl text-main" /><br/>
                                    <span className="text-md text-gray-500">{localStorage.regNum}</span>
                                </Link>
                            </li>
                            <li className="nav-menu__item">
                                <Link to="/account" className="nav-menu__link text-sm text-gray-500">
                                    <i className="pi pi-id-card text-main" />
                                    &nbsp;Хувийн мэдээлэл
                                </Link>
                            </li>
                            <li className="nav-menu__item">
                                <Link to="/invoice" className="nav-menu__link text-sm text-gray-500">
                                    <i className="pi pi-book text-main" />
                                    &nbsp;Миний нэхэмжлэлүүд
                                </Link>
                            </li>
                            <li className="nav-menu__item">
                                <Link onClick={() => setVisible(true)} className="nav-menu__link text-sm text-gray-500">
                                    <i className="ph ph-sign-out text-main" />
                                    &nbsp;Гарах
                                </Link>
                            </li>
                            <li className="nav-menu__item">
                                <img className="w-100" src="../../assets/images/bg/login.png" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* ==================== Mobile Menu End Here ==================== */}
            
            {/* ==================== Search Box Start Here ==================== */}
            {/* <form action="#" className={`search-box ${activeSearch && "active"}`}>
                <button onClick={handleSearchToggle}
                    type="button"
                    className="search-box__close position-absolute inset-block-start-0 inset-inline-end-0 m-16 w-48 h-48 border border-gray-100 rounded-circle flex-center text-white hover-text-gray-800 hover-bg-white text-2xl transition-1"
                >
                    <i className="ph ph-x" />
                </button>
                <div className="container">
                    <div className="position-relative">
                        <input
                            type="text"
                            className="form-control py-16 px-24 text-xl rounded-pill pe-64"
                            placeholder="Хайх утгаа оруулна уу..."
                        />
                        <button
                            type="submit"
                            className="w-48 h-48 bg-main-600 rounded-circle flex-center text-xl text-white position-absolute top-50 translate-middle-y inset-inline-end-0 me-8"
                        >
                            <i className="ph ph-magnifying-glass" />
                        </button>
                    </div>
                </div>
            </form> */}
            {/* ==================== Search Box End Here ==================== */}
            {/* ==================== Header Start Here ==================== */}
            <header className={`header bg-white border-bottom border-gray-100 ${scroll && "fixed-header"}`}>
                <div className="container container-lg">
                    <nav className="header-inner d-flex justify-content-between-md-evenly gap-8">
                        <div className="flex-align menu-category-wrapper">
                            <div className="category on-hover-item">
                                <button
                                    onClick={handleCategoryToggle}
                                    type="button"
                                    className="category__button flex-align gap-8 fw-medium bg-main-600 p-16 border-end border-start border-gray-100 text-white">
                                    <span className="icon text-2xl d-xs-flex d-none">
                                        <i className="ph ph-dots-nine" />
                                    </span>
                                    <span className="d-sm-flex d-none">Ангилал </span>
                                    <span className="arrow-icon text-xl d-flex">
                                        <i className="ph ph-caret-down" />
                                    </span>
                                </button>
                                <div className={`responsive-dropdown cat on-hover-dropdown common-dropdown nav-submenu p-0 submenus-submenu-wrapper ${activeCategory && "active"}`}>
                                    <button
                                        onClick={() => { handleCategoryToggle(); setActiveIndexCat(null) }}
                                        type="button"
                                        className="close-responsive-dropdown rounded-circle text-xl position-absolute inset-inline-end-0 inset-block-start-0 mt-4 me-8 d-lg-none d-flex"
                                    >
                                        {" "}
                                        <i className="ph ph-x" />{" "}
                                    </button>
                                    {/* Logo Start */}
                                    <div className="logo px-16 d-lg-none d-block">
                                        <Link to="/" className="link">
                                            <img src="../../assets/images/logo/logo.png" />
                                        </Link>
                                    </div>
                                    {/* Logo End */}
                                    <ul className="scroll-sm p-0 py-8 max-h-400 overflow-y-auto">
                                        {
                                            (categories || []).map((cat, index) => (
                                                <li onClick={() => handleCatClick(index)} className={`has-submenus-submenu  ${activeIndexCat === index ? "active" : ""}`}>
                                                    <Link onClick={() => setActiveIndexCat(index)} to={"/shop/" + cat.id} className="text-gray-500 text-15 py-12 px-16 flex-align gap-8 rounded-0">
                                                        <span className="text-xl d-flex">
                                                            <i className="pi pi-th-large text-main" />
                                                            {/* <img src={'https://ingumel.mn/backend/' + cat.cat_icon} className="h-26" /> */}
                                                        </span>
                                                        {/* <span>&nbsp;{cat.cat_name + ' (' + cat.products + ' бараа)'}</span> */}
                                                        <span>&nbsp;{cat.cat_name}</span>
                                                        {
                                                            (childCategories && childCategories.filter((i) => i.parent_id === cat.id).length > 0) &&
                                                                (<span className="icon text-md d-flex ms-auto">
                                                                    <i className="ph ph-caret-right" />
                                                                </span>)
                                                        }
                                                    </Link>
                                                    {
                                                        (childCategories && childCategories.filter((i) => i.parent_id === cat.id).length > 0) ?
                                                        <div className={`submenus-submenu py-16 ${activeIndexCat === index ? "open" : ""}`}>
                                                            <ul className="submenus-submenu__list max-h-300 overflow-y-auto scroll-sm">
                                                                {
                                                                    childCategories.filter((i) => i.parent_id === cat.id).map((child) => (
                                                                        <li>
                                                                            <Link to={"/shop/" + child.id}>{child.cat_name}</Link>
                                                                        </li>))
                                                                }
                                                            </ul>
                                                        </div> : null
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            {/* Menu Start  */}
                            <div className="header-menu d-lg-block d-none">
                                {/* Nav Menu Start */}
                                <ul className="nav-menu flex-align ">
                                    <li className=" nav-menu__item">
                                        <div className="logo">
                                            <Link to="/" className="link">
                                                <img src="../../assets/images/logo/logo.png" />
                                            </Link>
                                        </div>
                                    </li>
                                    {/* <li className="nav-menu__item">
                                        <form action="#" className="flex-align flex-wrap form-location-wrapper">
                                            <div className="search-category d-flex h-48 select-border-end-0 radius-end-0 search-form d-sm-flex d-none">
                                                <div className="search-form__wrapper position-relative">
                                                    <input
                                                        type="text"
                                                        className="search-form__input common-input py-13 ps-16 pe-18 pe-44"
                                                        placeholder="Хайх утгаа оруулна уу..."
                                                    />
                                                    <button
                                                        type="submit"
                                                        className="w-32 h-32 bg-main-600 rounded-circle flex-center text-xl text-white position-absolute top-50 translate-middle-y inset-inline-end-0 me-8"
                                                    >
                                                        <i className="ph ph-magnifying-glass" />
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        {/* Header Right start */}
                        <div className="header-right flex-align">
                            <ul className="nav-menu flex-align ">
                                <li className="nav-menu__item">
                                    <div className="flex-align flex-wrap gap-12">
                                        <Link to="/cart" className="flex-align gap-4 item-hover">
                                            <span className="text-2xl text-gray-700 d-flex position-relative me-6 mt-6 item-hover__text">
                                                <i className="ph ph-shopping-cart-simple" />
                                                <span className="w-16 h-16 flex-center rounded-circle bg-main-600 text-white text-xs position-absolute top-n6 end-n4">
                                                    { cartCount ? cartCount : cartItems}
                                                </span>
                                            </span>
                                            <span className="text-sm text-gray-500 item-hover__text d-none d-lg-flex">
                                                Сагс
                                            </span>
                                        </Link>
                                    </div>
                                </li>
                                <li className="on-hover-item nav-menu__item has-submenu">
                                    <div className="flex-align flex-wrap gap-12">
                                        <Link to="/account" className="selected-text text-sm py-8 flex-align gap-6">
                                            <span className="text-2xl text-gray-700 d-flex position-relative me-6 mt-6 item-hover__text">
                                                <i className="ph ph-user-circle" />
                                            </span>
                                            <span className="text-sm text-gray-500 item-hover__text d-none d-lg-flex">{localStorage.userName}</span>
                                        </Link>
                                        <ul className="selectable-text-list on-hover-dropdown common-dropdown common-dropdown--md max-h-200 scroll-sm px-0 py-8">
                                            <li>
                                                <Link to="/account" className="hover-bg-gray-100 text-gray-500 text-sm py-10 px-16 flex-align gap-8 rounded-0">
                                                    <i className="pi pi-id-card" style={{color: '#0771a6'}} />
                                                    Хувийн мэдээлэл
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/invoice" className="hover-bg-gray-100 text-gray-500 text-sm py-10 px-16 flex-align gap-8 rounded-0">
                                                    <i className="pi pi-book" style={{color: '#0771a6'}} />
                                                    Миний нэхэмжлэлүүд
                                                </Link>
                                            </li>
                                            <li>
                                                <Link onClick={() => setVisible(true)} className="hover-bg-gray-100 text-gray-500 text-sm py-10 px-16 flex-align gap-8 rounded-0">
                                                    <i className="ph ph-sign-out" style={{color: '#0771a6'}} />
                                                    Гарах
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-menu__item">
                                    <Link to="/tel:97677038585"
                                        className="bg-main-600 text-white p-12 h-100 hover-bg-main-800 flex-align gap-8 text-md d-lg-flex d-none">
                                        <div className="d-flex text-20">
                                            <i className="ph ph-phone-call" />
                                        </div>
                                        +976 7703-8585
                                    </Link>
                                </li>
                            </ul>                            
                            {/* <div className="me-16 d-lg-none d-block">
                                <div className="flex-align flex-wrap gap-12"> */}
                                    {/* <button onClick={handleSearchToggle}
                                        type="button"
                                        className="search-icon flex-align d-lg-none d-flex gap-4 item-hover">
                                        <span className="text-2xl text-gray-700 d-flex position-relative me-6 mt-6 item-hover__text">
                                            <i className="ph ph-magnifying-glass" />
                                        </span>
                                    </button>    */}
                                    {/* <Link to="/cart" className="flex-align gap-4 item-hover">
                                        <span className="text-2xl text-gray-700 d-flex position-relative me-6 mt-6 item-hover__text">
                                            <i className="ph ph-shopping-cart-simple" />
                                            <span className="w-16 h-16 flex-center rounded-circle bg-main-600 text-white text-xs position-absolute top-n6 end-n4">
                                                { cartCount ? cartCount : cartItems}
                                            </span>
                                        </span>
                                        <span className="text-md text-gray-500 item-hover__text d-none d-lg-flex">
                                            Сагс
                                        </span>
                                    </Link>
                                    <Link to="/account" className="flex-align gap-4 item-hover">
                                        <span className="text-2xl text-gray-700 d-flex position-relative me-6 mt-6 item-hover__text">
                                            <i className="ph ph-user-circle" />
                                        </span>
                                        <span className="text-md text-gray-500 item-hover__text d-none d-lg-flex">{localStorage.userName}</span>
                                    </Link>
                                </div>
                            </div> */}
                            <button onClick={handleMenuToggle} type="button"
                                className="toggle-mobileMenu d-lg-none ms-3n text-main text-4xl d-flex">
                                {" "}
                                <i className="ph ph-list" />{" "}
                            </button>
                        </div>
                        {/* Header Right End  */}
                    </nav>
                </div>
            </header>
            <Dialog open={visible} onClose={() => setVisible(false)}>
                <DialogTitle style={{color: '#0771a6', fontWeight: 'bold'}} className="text-md">Анхаар!</DialogTitle>
                    <DialogContent>
                    <DialogContentText className='text-sm'><span className='pi pi-info-circle' style={{color: '#0771a6'}}></span>{" "}Та үнэхээр системээс гарахыг хүсч байна уу?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='btn-main text-white text-xs' onClick={cancel}><span className='pi pi-times'></span>&nbsp;Үгүй</Button>
                    <Button className='btn-danger text-xs' style={{color: '#fff', background: '#ff0000'}} onClick={confirm}><span className='pi pi-check'></span>&nbsp;Тийм</Button>
                </DialogActions>
            </Dialog>
            {/* ==================== Header End Here ==================== */}
        </>
    )
}

export default Header