import React, { useState } from "react";
import Preloader from "../helper/Preloader";
import Header from "../components/Header";
import ProductDetails from "../components/ProductDetails";
import Footer from "../components/Footer";
import BottomFooter from "../components/BottomFooter";
import BottomNav from '../components/BottomNav';
import ScrollToTop from "react-scroll-to-top";

const ProductDetailsPage = () => {
  const [cartCount, setCartCount] = useState(localStorage.getItem('cartLength') ? localStorage.getItem('cartLength') : 0);
  const addToCartHeader = () => {
      const currentQuantity = Number(cartCount);
      setCartCount(currentQuantity + Number(1));
  };

  return (
    <>
      {/* Preloader */}
      <Preloader />

      {/* ScrollToTop */}
      <ScrollToTop smooth color="#0771a6" />

      {/* Header */}
      <Header cartCount={cartCount}/>

      {/* ProductDetails */}
      <ProductDetails addToCartHeader={addToCartHeader}/>

      {/* BottomNav */}
      <BottomNav />

      {/* Footer */}
      <Footer />

      {/* BottomFooter */}
      <BottomFooter />
    </>
  );
};

export default ProductDetailsPage;
