import React, {useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Feature = () => {
    const [categories, setCategories] = useState();
    const [totalElements, setTotalElements] = useState(0);

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return (
            <button
                type="button" onClick={onClick}
                className={` ${className} slick-next slick-arrow flex-center rounded-circle bg-white text-xl text-main-600 hover-bg-main-600 hover-text-white transition-1`}
            >
                <i className="ph ph-caret-right" />
            </button>
        );
    }

    function SamplePrevArrow(props) {
        const { className, onClick } = props;

        return (
            <button
                type="button"
                onClick={onClick}
                className={`${className} slick-prev slick-arrow flex-center rounded-circle bg-white text-xl text-main-600 hover-bg-main-600 hover-text-white transition-1`}
                >
                <i className="ph ph-caret-left" />
            </button>
        );
    }

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 10,
        autoplay: true,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1699,
                settings: {
                    slidesToShow: 10,
                },
            },
            {
                breakpoint: 1599,
                settings: {
                    slidesToShow: 10,
                },
            },
            {
                breakpoint: 1499,
                settings: {
                    slidesToShow: 9,
                },
            },
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 8,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 840,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 424,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 359,
                settings: {
                    slidesToShow: 2,
                },
            },

        ],
    };

    useEffect(() => {
        let isMounted = true;
        const fetchCategories = async () => {
          try {
            const response = await axios({
              url: process.env.REACT_APP_API_URL + "/api/category/all",
              headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
              method: "GET"
            });
            if (isMounted) {
              setTotalElements(response.data.totalElements);
              setCategories(response.data.category_info);
            }
          } catch (error) {
            if (isMounted) {
              toast.error('Сүлжээний алдаа гарлаа!' + error, {autoClose: 3500});
            }
          }
        };
        fetchCategories();
        return () => {
          isMounted = false;
        };
    }, []);

    return (
        <div className="feature" id="featureSection">
            <ToastContainer />
            <div className="container container-lg">
                <div className="position-relative arrow-center">
                    <div className="flex-align">
                        <button
                            type="button"
                            id="feature-item-wrapper-prev"
                            className="slick-prev slick-arrow flex-center rounded-circle bg-white text-xl hover-bg-main-600 hover-text-white transition-1"
                        >
                            <i className="ph ph-caret-left" />
                        </button>
                        <button
                            type="button"
                            id="feature-item-wrapper-next"
                            className="slick-next slick-arrow flex-center rounded-circle bg-white text-xl hover-bg-main-600 hover-text-white transition-1"
                        >
                            <i className="ph ph-caret-right" />
                        </button>
                    </div>
                    <div className="feature-item-wrapper">
                        <Slider {...settings}>
                            {
                                (categories || []).map((cat) => (
                                    <div className="feature-item text-center">
                                        <div className="feature-item__thumb rounded-circle">
                                            <Link to={"/shop/" + cat.id} className="w-100 h-100 flex-center">
                                                <img src={'https://ingumel.mn/backend/' +  cat.cat_icon } />
                                            </Link>
                                        </div>
                                        <div className="feature-item__content mt-16">
                                            <h6 className="text-sm mb-8">
                                                <Link to={"/shop/" + cat.id} className="text-inherit">
                                                    {cat.cat_name}
                                                </Link>
                                            </h6>
                                            <span className="text-sm text-gray-400">{cat.products} бараа</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Feature