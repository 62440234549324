import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { Tree } from 'primereact/tree';
import './vendors.css';
import 'react-toastify/dist/ReactToastify.css';

const Shop = () => {
    const {categoryId } = useParams();
    const [exchRate, setExchRate] = useState(0);
    const [products, setProducts] = useState();
    const [categories, setCategories] = useState([]);
    const [parentCategories, setParentCategories] = useState([]);
    const [childCategories, setChildCategories] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [nodes, setNodes] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState(null);
    let [grid, setGrid] = useState(false);
    let [active, setActive] = useState(false);
    let sidebarController = () => {
        setActive(!active)
    }

    const handleCheckBox = (event) => {
        if (event.target.checked)
            setSelectedCategories([...selectedCategories, event.target.value]);
        else {
            const list = [...selectedCategories];
            var index = list.indexOf(event.target.value)
            if (index !== -1) {
                list.splice(index, 1);
                setSelectedCategories(list);
            }
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const totalPages = Math.ceil(totalElements / perPage);

    const paginationList = [];

    for (let i = 0; i < totalPages; i++) {
        paginationList.push(
            <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                <button onClick={() => handlePageChange(i + 1)}
                    className="page-link h-64 w-64 flex-center text-md rounded-circle fw-medium text-neutral-600 border border-gray-100">
                    {i + 1}
                </button>
            </li>
        );
    };

    const buildTree = (parentCategories, childCategories) => {
        return parentCategories.map((parent, index) => {
            const children = childCategories
                .filter(child => child.parent_id === parent.id)
                .map((child, index) => ({key: index + 1, id: child.id, label: child.cat_name }));

            return {
                key: index + 1,
                id: parent.id,
                label: parent.cat_name,
                children: children.length > 0 ? children : null
            };
        });
    };

    const handleNodeSelect = (event) => {
        const selectedNode = event.node; // Use event.node instead of event.value
        if (selectedNode) {
            setSelectedCategories(prev => {
                if (!prev.includes(selectedNode.id)) {
                    return [...prev, selectedNode.id]; // Add the selected node ID
                }
                return prev; // Return unchanged state if already selected
            });
        }
    };
    
    const handleNodeUnselect = (event) => {
        const unselectedNode = event.node; // Use event.node instead of event.value
        if (unselectedNode) {
            setSelectedCategories(prev => prev.filter(id => id !== unselectedNode.id)); // Remove the unselected node ID
        }
    };

    useEffect(() => {
        let isMountedProduct = true;
        let isMountedCategory = true;
        if (categoryId && !selectedCategories.includes(categoryId)) {
            setSelectedCategories(prev => [...prev, categoryId]);
        }

        const fetchProducts = async () => {
            try {
                const response = await axios({
                    url: process.env.REACT_APP_API_URL + "/api/product/category",
                    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
                    data: JSON.stringify({
                        cat_id: selectedCategories.length > 0 ? selectedCategories : null,
                        perPage: perPage,
                        page: currentPage,
                        pro_search: searchValue
                    }),
                    method: "POST"
                });
                if (isMountedProduct) {
                    setProducts(response.data.product_info);
                    setExchRate(response.data.exchangeRate);
                    setTotalElements(response.data.totalElements);
                }
            } catch (error) {
                if (isMountedProduct)
                    toast.error('Сүлжээний алдаа гарлаа!' + error, { autoClose: 3500 });
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axios({
                    url: process.env.REACT_APP_API_URL + "/api/category/all",
                    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
                    method: "GET"
                });
                if (isMountedCategory) {
                    setCategories(response.data.category_info);

                    const parentCategories = response.data.category_info.filter(cat => cat.parent_id == 0);
                    setParentCategories(parentCategories);

                    const childCategories = response.data.category_info.filter(cat => cat.parent_id != 0);
                    setChildCategories(childCategories);

                    const treeData = buildTree(parentCategories, childCategories);
                    setNodes(treeData);
                }
            } catch (error) {
                if (isMountedCategory)
                    toast.error('Сүлжээний алдаа гарлаа!' + error, {autoClose: 3500});
            }
        };

        fetchProducts();
        fetchCategories();
        
        return () => {
            isMountedProduct = false;
            isMountedCategory = false;
        };
    }, [selectedCategories, perPage, currentPage, categoryId, searchValue]);

    return (
        <section className="vendor-two mt-30">
            <ToastContainer />
            <div className={`side-overlay ${active && "show"}`}></div>
            <div className="container container-lg">
                <div className="row">
                    {/* Sidebar Start */}
                    <div className="col-xl-3 col-lg-4">
                        <div className={`shop-sidebar ${active && "active"}`}>
                            <button onClick={sidebarController}
                                type="button"
                                className="shop-sidebar__close d-lg-none d-flex w-32 h-32 flex-center border border-gray-100 rounded-circle hover-bg-main-600 position-absolute inset-inline-end-0 me-10 mt-8 hover-text-white hover-border-main-600"
                            >
                                <i className="ph ph-x" />
                            </button>
                            <div className="shop-sidebar__box border border-gray-100 rounded-8 p-32 mb-32">
                                <h6 className="text-lg border-bottom border-gray-100 pb-24 mb-24">
                                    Барааны ангилал
                                </h6>
                                <Tree value={nodes} selectionMode="checkbox" className="custom-tree" 
                                    selectionKeys={selectedKeys} onSelectionChange={e => setSelectedKeys(e.value)}
                                    onSelect={handleNodeSelect} onUnselect={handleNodeUnselect} />
                            </div>
                            <div className="shop-sidebar__box border border-gray-100 rounded-8 p-32 mb-32">
                                <h6 className="text-lg border-bottom border-gray-100 pb-24 mb-24">
                                    Барааны нэрээр хайх
                                </h6>
                                <div className="flex-align gap-8 position-relative mb-20">
                                    <input type="text" placeholder="Хайх утгаа оруулна уу..."
                                        value={searchValue} onChange={(e) =>  setSearchValue(e.target.value)}
                                        className="search-form__input common-input py-13 ps-16 pe-18 pe-44" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Sidebar End */}
                    <div className="col-xl-9 col-lg-8">
                        {/* Top Start */}
                        <div className="flex-between gap-16 flex-wrap mb-40 ">
                            {
                                totalElements > perPage ? 
                                (
                                    <span className="text-gray-900">Нийт {totalElements} бараанаас 1-{perPage} хүртэлхийг харуулж байна
                                    </span>
                                ) :
                                (
                                    <span className="text-gray-900">Нийт {totalElements} бараанаас 1-{totalElements} хүртэлхийг харуулж байна
                                    </span>
                                )
                            }
                            <div className="position-relative flex-align gap-16 flex-wrap">
                                <div className="position-relative text-gray-600 flex-align gap-4 text-14">
                                    <label htmlFor="sorting" className="text-inherit flex-shrink-0 text-gray-600">
                                        Харуулах барааны тоо:{" "}
                                    </label>
                                    <select className="form-control common-input px-14 py-14 text-inherit rounded-6 w-auto"
                                        value={perPage} onChange={(e) => setPerPage(e.target.value)} id="sorting">
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={30}>30</option>
                                        <option value={40}>40</option>
                                        <option value={50}>50</option>
                                        {/* <option value={100}>100</option> */}
                                    </select>
                                </div>
                                <div className='d-flex align-items-center gap-8 d-sm-flex d-none'>
                                    <button onClick={() => setGrid(false)} type='button'
                                        className={`w-44 h-44 flex-center border rounded-6 text-2xl grid-btn border-gray-100 ${grid === false && "border-main-600 text-white bg-main-600"}`}>
                                        <i className='ph ph-squares-four' />
                                    </button>
                                    <button onClick={() => setGrid(true)} type='button'
                                        className={`w-44 h-44 flex-center border rounded-6 text-2xl list-btn border-gray-100 ${grid === true && "border-main-600 text-white bg-main-600"}`}>
                                        <i className='ph-bold ph-list-dashes' />
                                    </button>
                                </div>
                                <button onClick={sidebarController} type='button'
                                    className='w-48 h-48 d-lg-none d-flex flex-center border border-gray-100 rounded-6 text-2xl sidebar-btn'>
                                    <i className='ph-bold ph-funnel' />
                                </button>
                            </div>
                        </div>
                        
                        <div className={`list-grid-wrapper vendors-two-item-wrapper grid-cols-3 ${grid && "list-view"}`}>
                        {
                        products && products.length > 0 ? 
                            (products || []).map((pro) => (
                                <div className="vendors-two-item rounded-12 overflow-hidden bg-color-three border border-neutral-50 hover-border-main-600 transition-2">
                                    <div className='vendors-two-item__top bg-overlay style-two position-relative'>
                                        <div className='vendors-two-item__thumbs' style={{height: '13rem'}}>
                                            {/* <img src='https://react.marketpro.wowtheme7.com/assets/images/thumbs/vendors-two-img1.png' className="cover-img"/> */}
                                            <img src={'https://ingumel.mn/backend/' + pro.pro_image} className="cover-img"/>
                                        </div>
                                        <div className='position-absolute top-0 inset-inline-start-0 w-100 h-100 p-24 z-1 d-flex flex-column justify-content-between'>
                                            <div className='d-flex align-items-center justify-content-between'></div>
                                            <div className='mt-16'>
                                                <h6 className='text-white fw-semibold mb-12'>
                                                    <Link to={"/product/" + pro.pro_code} className="text-line-2 text-md hover-text-white">
                                                        {pro.pro_name}
                                                    </Link>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vendors-two-item__content p-24 flex-grow-1">
                                        <div className="d-flex flex-column gap-14">
                                            <div className="flex-align gap-8">
                                                <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                    <i className="pi pi-th-large" />
                                                </span>
                                                <span className="text-sm text-gray-900">
                                                    {pro.cat_name}
                                                </span>
                                            </div>
                                            <div className="flex-align gap-8">
                                                <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                    <i className="pi pi-hashtag" />
                                                </span>
                                                <span className="text-sm text-gray-900">
                                                    Код: {pro.pro_code}
                                                </span>
                                            </div>
                                            <div className="flex-align gap-8">
                                                <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                    <i className="pi pi-money-bill" />
                                                </span>
                                                <span className="text-sm text-gray-900">
                                                    Үнэ: ¥{pro.pro_price}
                                                    <span>&nbsp;/&nbsp;{pro.pro_price * parseInt(exchRate)}₮</span>{" "}
                                                </span>
                                            </div>
                                        </div>
                                        {/* <Link to={"/product/" + pro.pro_code}
                                            className="btn bg-main-600 hover-bg-main-700 text-white py-12 px-24 rounded-8 flex-center gap-8 fw-medium mt-24">
                                            <span className="text-xl d-flex text-white">
                                                <i className="pi pi-list" />
                                            </span>
                                        </Link> */}
                                    </div>
                                </div>
                                ))
                            :
                            (<span className="text-gray-900 text-center">Бараа хоосон байна</span>)
                        }
                        </div>
                        {
                            
                            (products && products.length > 0) && 
                            <ul className="pagination flex-center flex-wrap gap-16">                            
                                <li className="page-item">
                                    <button
                                        className="page-link h-64 w-64 flex-center text-md fw-medium rounded-circle text-neutral-600 border border-gray-100" 
                                        onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage == 1}>
                                        <i className="ph-bold ph-arrow-left" />
                                    </button>
                                </li>
                                {paginationList}
                                <li className="page-item">
                                    <button
                                        className="page-link h-64 w-64 flex-center text-md rounded-circle fw-medium text-neutral-600 border border-gray-100" 
                                        onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage == totalPages}>
                                        <i className="ph-bold ph-arrow-right" />
                                    </button>
                                </li>
                            </ul>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Shop