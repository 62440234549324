import React from "react";
import Preloader from "../helper/Preloader";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BottomFooter from "../components/BottomFooter";
import Shipping from "../components/Shipping";
import Invoice from "../components/Invoice";
import BottomNav from '../components/BottomNav';
import ScrollToTop from "react-scroll-to-top";

const InvoicePage = () => {
  return (
    <>
      {/* Preloader */}
      <Preloader />

      {/* ScrollToTop */}
      <ScrollToTop smooth color="#0771a6" />

      {/* Header */}
      <Header />

      {/* Invoice */}
      <Invoice />

      {/* BottomNav */}
      <BottomNav />

      {/* Shipping */}
      <Shipping />

      {/* Footer */}
      <Footer />

      {/* BottomFooter */}
      <BottomFooter />
    </>
  );
};

export default InvoicePage;
