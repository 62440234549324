import React, { useState } from "react";
import Preloader from "../helper/Preloader";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BottomFooter from "../components/BottomFooter";
import CartSection from "../components/CartSection";
import BottomNav from '../components/BottomNav';
import Shipping from "../components/Shipping";
import ScrollToTop from "react-scroll-to-top";

const CartPage = () => {
  const [cartCount, setCartCount] = useState(localStorage.getItem('cartLength') ? localStorage.getItem('cartLength') : 0);
  const removeFromCart = () => {
    setCartCount(cartCount - 1);
  };
  return (
    <>
      {/* Preloader */}
      <Preloader />

      {/* ScrollToTop */}
      <ScrollToTop smooth color="#0771a6" />

      {/* Header */}
      <Header cartCount={cartCount}/>

      {/* CartSection */}
      <CartSection removeFromCart={removeFromCart}/>

      {/* Shipping */}
      <Shipping />

      {/* BottomNav */}
      <BottomNav />

      {/* Footer */}
      <Footer />

      {/* BottomFooter */}
      <BottomFooter />
    </>
  );
};

export default CartPage;
