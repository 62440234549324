import React from "react";
import Preloader from "../helper/Preloader";
import HeaderLogin from "../components/HeaderLogin";
import BottomFooter from "../components/BottomFooter";
import Login from "../components/Login";
import ScrollToTop from "react-scroll-to-top";

const LoginPage = () => {

  return (
    <>
      {/* Preloader */}
      <Preloader />
      
      {/* ScrollToTop */}
      <ScrollToTop smooth color="#0771a6" />

      {/* Header */}
      <HeaderLogin />

      {/* Account */}
      <Login />

      {/* BottomFooter */}
      <BottomFooter />
    </>
  );
};

export default LoginPage;
