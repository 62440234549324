import React from "react";
import Preloader from "../helper/Preloader";
import Header from "../components/Header";
import Feature from "../components/Feature";
import Recommended from "../components/Recommended";
import Delivery from "../components/Delivery";
import Shipping from "../components/Shipping";
import Footer from "../components/Footer";
import BottomFooter from "../components/BottomFooter";
import ScrollToTop from "react-scroll-to-top";
import BottomNav from '../components/BottomNav';

const HomePage = () => {

  return (
    <>
      {/* Preloader */}
      <Preloader />

      {/* ScrollToTop */}
      <ScrollToTop smooth color="#0771a6" />

      {/* Header */}
      <Header />

      {/* BottomNav */}
      <BottomNav />

      {/* Feature */}
      <Feature />

      {/* Recommended */}
      <Recommended />

      {/* Delivery */}
      <Delivery />

      {/* Shipping */}
      <Shipping />

      {/* Footer */}
      <Footer />

      {/* BottomFooter */}
      <BottomFooter />
    </>
  );
};

export default HomePage;
