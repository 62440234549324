import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userId, setUserId] = useState('');

  const login = (user_name, phone_number, user_id) => {
    setUserName(user_name);
    setPhoneNumber(phone_number);
    setUserId(user_id);
  };

  const logout = () => {
    setUserName('');
    setPhoneNumber('');
    setUserId('');
    localStorage.removeItem('userName');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('regNum');
    localStorage.removeItem('userId');
    localStorage.removeItem('userSoumName');
    localStorage.removeItem('userCityName');
    localStorage.removeItem('userSoumId');
    localStorage.removeItem('userCityId');
    localStorage.removeItem('userFB');
    // localStorage.removeItem('cart');
    // localStorage.removeItem('cartLength');
  };

  return (
    <AuthContext.Provider value={{ userName, phoneNumber, userId, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}