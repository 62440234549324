import React, {useState} from 'react';

const QuantityControl = ({ initialQuantity, updateQty}) => {
    const [quantity, setQuantity] = useState(initialQuantity);
    
    const incrementQuantity = () => {
        setQuantity(quantity + initialQuantity);
        updateQty(quantity + initialQuantity);
    };

    const decrementQuantity = () => {
        if (quantity > initialQuantity) {
            setQuantity(quantity - initialQuantity);
            updateQty(quantity - initialQuantity);
        }
    };

    const handleInputChange = (e) => {
        const newQty = Number(e.target.value);
        setQuantity(newQty);
        updateQty(newQty);
    };

    return (
        <div className="d-flex rounded-4 overflow-hidden">
            <button
                type="button"
                onClick={decrementQuantity}
                disabled={quantity < initialQuantity ? true : false}
                className="quantity__minus border border-end border-gray-100 flex-shrink-0 h-48 w-48 text-neutral-600 flex-center hover-bg-main-600 hover-text-white"
            >
                <i className="ph ph-minus" />
            </button>
            <input
                type="number"
                className="quantity__input text-main text-sm flex-grow-1 border border-gray-100 border-start-0 border-end-0 text-center w-32 px-4"
                value={quantity}
                onChange={handleInputChange}
                min={initialQuantity}
                readOnly
            />
            <button
                type="button"
                onClick={incrementQuantity}
                className="quantity__plus text-main border border-end border-gray-100 flex-shrink-0 h-48 w-48 text-neutral-600 flex-center hover-bg-main-600 hover-text-white"
            >
                <i className="ph ph-plus" />
            </button>
        </div>
    );
};

export default QuantityControl;