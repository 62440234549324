import React from 'react'

const ShippingOne = () => {
    return (
        <section className="shipping mt-24" id="shipping">
            <div className="container container-lg">
                <div className="row gy-4">
                    <div className="col-xxl-3 col-sm-6">
                        <div className="shipping-item flex-align gap-16 rounded-16 bg-main-50 hover-bg-main-100 transition-2">
                            <span className="w-56 h-56 flex-center rounded-circle bg-main-600 text-white text-26 flex-shrink-0">
                                <i className="ph-fill ph-car-profile" />
                            </span>
                            <div className="">
                                <h6 className="mb-5 text-center text-lg">Орон нутаг</h6>
                                <span className="text-sm text-heading text-center">
                                    Бид таны ачааг орон нутгийн унаанд тавьж өгнө
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-sm-6">
                        <div className="shipping-item flex-align gap-16 rounded-16 bg-main-50 hover-bg-main-100 transition-2">
                            <span className="w-56 h-56 flex-center rounded-circle bg-main-600 text-white text-26 flex-shrink-0">
                                <i className="ph-fill ph-hand-heart" />
                            </span>
                            <div className="">
                                <h6 className="mb-5 text-center text-lg"> Сэтгэл ханамж</h6>
                                <span className="text-sm text-heading text-center">
                                    Танд 100%-ийн сэтгэл ханамжийг амлаж байна
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-sm-6">
                        <div className="shipping-item flex-align gap-16 rounded-16 bg-main-50 hover-bg-main-100 transition-2">
                            <span className="w-56 h-56 flex-center rounded-circle bg-main-600 text-white text-26 flex-shrink-0">
                                <i className="ph-fill ph-credit-card" />
                            </span>
                            <div className="">
                                <h6 className="mb-5 text-center text-lg"> Найдвартай төлөлт</h6>
                                <span className="text-sm text-heading text-center">
                                    Та өөрийн төлбөр төлөлтөнд итгэлтэй байж болно
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-sm-6">
                        <div className="shipping-item flex-align gap-16 rounded-16 bg-main-50 hover-bg-main-100 transition-2">
                            <span className="w-56 h-56 flex-center rounded-circle bg-main-600 text-white text-26 flex-shrink-0">
                                <i className="ph-fill ph-chats" />
                            </span>
                            <div className="">
                                <h6 className="mb-5 text-center text-lg"> 24/7 тусламж</h6>
                                <span className="text-sm text-heading text-center">
                                    Манай хамт олон танд 24/7 тусламжийг үзүүлэх болно
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ShippingOne