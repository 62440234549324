import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const HeaderLogin = () => {
    const [scroll, setScroll] = useState(false);
    
    useEffect(() => {
        window.onscroll = () => {
            if (window.pageYOffset < 150) {
                setScroll(false);
            } else if (window.pageYOffset > 150) {
                setScroll(true);
            }
            return () => (window.onscroll = null);
        };
    }, []);

    return (
        <>
            <header className="header-middle style-two bg-color-neutral">
                <div className="container container-lg">
                    <nav className="header-inner flex-between">
                        <div className="logo">
                            <Link to="/" className="link">
                                <img src="../../assets/images/logo/logo-two.png"/>
                            </Link>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    )
}

export default HeaderLogin